<template>
    <div>
        <ModelHelp v-show="modelHelpContext" @selectModel="selectModel" @selectPart="selectPart" />
        <DimensionLookUp ref="dimensions" />
        <div v-show="!curb.curb_type_id">
            <h3>Add Item</h3>
            <div class="row">
                <div v-for="curb in featured" :key="curb.curb_type_id" class="col-lg-3 col-sm-6 col-xs-12">
                    <div class="item_panel_featured" @click="selectCurbType(curb.curb_type_id, curb.curb_type)">
                        <img :src="`${imgServer}/images/v3/${curb.img}`"><span>{{ curb.curb_type }}</span>
                    </div>
                </div>
            </div>

            <h4>More Products</h4>
            <div class="row">
                <div v-for="[curb_type_id, curbType] in moreProducts" :key="curb_type_id" class="col-sm-6 col-xs-12">
                    <div class="item_panel" @click="selectCurbType(curb_type_id, curbType)">{{ curbType }}</div>
                </div>
            </div>
        </div>

        <div v-show="curb.curb_type_id && !modelHelpContext">
            <h3>Add {{ curb.curb_type }}</h3>
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Quantity</label>
                    <input class="form-control" type="text" v-model="curb.qty">
                </div>
                <div v-if="$appStore.isStaff()" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>$ Override</label>
                    <input type="text" class="form-control" v-model="curb.price_override">
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>RTU Tag</label>
                    <input class="form-control" type="text" maxlength="250" v-model="curb.tag">
                </div>
            </div>

            <div class="row">
                <div v-show="showSideType" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Type</label>
                    <select class="form-control" v-model="curb.side_type_id">
                        <option v-for="[side_type_id, sideType] in sideTypes" :value="side_type_id" :key="side_type_id">{{ sideType }}</option>
                    </select>
                </div>
                <div v-show="curb.curb_type_id == c.EXTENSION" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Type</label>
                    <select class="form-control" v-model="curb.extension_id">
                        <option v-for="[extension_id, extensionType] in c.EXTENSION_TYPE" :value="extension_id" :key="extension_id">{{ extensionType }}</option>
                    </select>
                </div>
                <div v-show="showPitch" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Pitch</label>
                    <select class="form-control" v-model="curb.pitch_id">
                        <option v-for="[pitch_id, pitchType] in c.PITCHES" :value="pitch_id" :key="pitch_id">{{ pitchType }}</option>
                    </select>
                </div>
                <div v-show="curb.curb_type_id == c.ADJUSTABLE_PITCHED_CURB" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Pitch</label>
                    <select class="form-control" v-model="curb.pitch_id">
                        <option value="9">Adjustable</option>
                    </select>
                </div>
                <div v-show="showHeight" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Height <small>(inches)</small></label>
                    <input type="text" class="form-control" v-model="curb.base_height">
                </div>

                <div v-show="curb.curb_type_id == c.PITCHED || curb.curb_type_id == c.METAL_BUILDING_CURB || curb.curb_type_id == c.VRF_PITCHED_CURB " class="col-xs-12 spacing_btm">
                    <div class="col-xs-12 form_grid spacing_top" style="margin-left: -15px;">
                        <label>Pitch Type</label> Orient your drawing to match one of the pictures below. Select the number that corresponds to the peak of your roof.
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                        <label><input type="radio" v-model="curb.eave_peak_selection" :value="c.LONG_SIDE_PEAK"> 1 or 5 (Long side pitch)</label>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                        <label><input type="radio" v-model="curb.eave_peak_selection" :value="c.SHORT_SIDE_PEAK"> 2 or 6 (Short side pitch)</label>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                        <label><input type="radio" v-model="curb.eave_peak_selection" :value="c.LONG_SIDE_EAVE"> 3 or 7 (Long side pitch)</label>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                        <label><input type="radio" v-model="curb.eave_peak_selection" :value="c.SHORT_SIDE_EAVE"> 4 or 8 (Short side pitch)</label>
                    </div>
                    <div v-show="curb.curb_type_id == c.PITCHED" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                        <label><input type="radio" v-model="curb.eave_peak_selection" :value="c.MULTI_PITCH_PEAK_EAVE"> Multi Pitched</label>
                    </div>
                    <div class='col-xs-12'>
                        <img :src="`${imgServer}/images/eave_peak.png`" alt='Eave or peak helper image' class='img-responsive'>
                    </div>
                </div>

                <div v-show="curb.curb_type_id == c.BURGLAR_BAR">
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group"><label>Spacing</label>
                        <select class="form-control" v-model="curb.spacing">
                            <option v-for="[burglarSpacingID, burglarSpacingType] in c.BURGLAR_SPACING" :value="burglarSpacingID" :key="burglarSpacingID">{{ burglarSpacingType }}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group"><label>Diameter</label>
                        <select class="form-control" v-model="curb.diameter">
                            <option v-for="[burglarRebarID, burglarRebarType] in c.BURGLAR_REBAR" :value="burglarRebarID" :key="burglarRebarID">{{ burglarRebarType }}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group"><label>Frame</label>
                        <select class="form-control" v-model="curb.frame">
                            <option v-for="[burglarFrameID, burglarFrameType] in c.BURGLAR_FRAME" :value="burglarFrameID" :key="burglarFrameID">{{ burglarFrameType }}</option>
                        </select>
                    </div>
                </div>

                <div v-show="curb.curb_type_id == c.CUSTOM" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Part</label>
                    <Typeahead ref="customPart" cdi_type="custom_part" v-bind:ID.sync="curb.cdi_part_id_old" @objectSelected="get_conforming_stamp_locations('custom')"/>
                </div>
                <div v-show="curb.curb_type_id == c.MISCELLANEOUS" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Part</label>
                    <Typeahead ref="miscPart" cdi_type="misc_part" v-bind:ID.sync="curb.cdi_part_id_old" />
                </div>

                <div v-show="showOld" class="col-xs-6 form-group">
                    <label>Old Model</label>
                    <Typeahead ref="oldModel" :cdi_type="'pm_model'" @objectSelected="pmSelect('old', ...arguments)" :placeholder="'Type unit model # or unknown'" :inputAttributes="{class: (curb.cdi_part_id_old == '314' ? 'alert-danger': '')}" />
                    <a @click="showHelp('old')" class="small cdi_link">I need help finding my Old Model</a>
                </div>
                <div v-show="curb.curb_type_id == c.ERV_COMBO" class="col-xs-6 form-group">
                    <label>ERV Model</label>
                    <Typeahead ref="ervModel" cdi_type="pm_erv" @objectSelected="pmSelect('old', ...arguments)" :placeholder="'Type unit model #'" :inputAttributes="{class: (curb.cdi_part_id_old == '314' ? 'alert-danger': '')}" />
                    <a @click="showHelp('erv')" class="small cdi_link">I need help finding my ERV Model</a>
                </div>

                <div v-show="curb.curb_type_id == c.ISO_RAIL" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Isolation Rail Type</label>
                    <input type="text" class="form-control" value='2" CDI ISOLATION RAIL' readonly>
                </div>

                <div v-show="curb.curb_type_id == c.CLIPS" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Clip</label>
                    <select v-model="curb.cdi_part_id_old" class="form-control">
                        <option value="872">S-Clip</option>
                        <option value="1216">Seismic S-Clip</option>
                        <option value="1333">L-Clip</option>
                        <option value="1440">KOHLS S-CLIPS</option>
                        <option value="1395">KOHLS L-CLIPS</option>
                        <option value="891">Z-CLIP</option>
                    </select>
                </div>

                <div v-show="showNew" class="col-xs-6 form-group">
                    <label>New Model</label>
                    <Typeahead ref="newModel" :cdi_type="computedCdiType" @objectSelected="pmSelect('new', ...arguments)" :placeholder="'Type unit model #'" :inputAttributes="{class: (curb.cdi_part_id_new == '314' ? 'alert-danger': '')}" />
                    <a @click="showHelp('new')" class="small cdi_link">I need help finding my New Model</a>
                    <div v-show="appStore.state.user.userTypeID === 4" class="right">
                        <label><input v-model="includeOldParts" @change="includeOldPartsFromOptions" type="checkbox"> Include Old Models</label>
                    </div>
                </div>

              <div v-show="showNewVrf" class="col-xs-6 form-group">
                <label>New Model VRF</label>
                <Typeahead ref="newModelVrf" cdi_type="pm_model_vrf" @objectSelected="pmSelect('new', ...arguments)" :placeholder="'Type unit model #'" :inputAttributes="{class: (curb.cdi_part_id_new == '314' ? 'alert-danger': '')}" />
                <a @click="showHelp('new')" class="small cdi_link">I need help finding my New Model</a>
              </div>

                <div v-show="curb.curb_type_id == c.EXHAUST_FAN_CURB" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Width <small>(inches)</small></label>
                    <input type="text" v-model="curb.width_ft" class="form-control">
                </div>
                <div v-show="curb.curb_type_id == c.EXHAUST_FAN_CURB" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label></label>
                    <select v-model="curb.width_inch_id" class="form-control">
                        <option v-for="[inchFractionID, inchFraction] in c.INCHES_FRACTIONS" :value="inchFractionID" :key="inchFractionID">{{ inchFraction }}</option>
                    </select>
                </div>
                <div v-show="curb.curb_type_id == c.VERT_CURB" class="col-md-3 col-sm-4 col-xs-6 form-group">
                  <label>Curb Depth</label>
                  <select v-model="curb.curb_depth_id" class="form-control">
                    <option v-for="[curbDepthID, curbDepth] in c.CURB_DEPTH" :value="curbDepthID" :key="curbDepthID">{{ curbDepth }}</option>
                  </select>
                </div>
                <div v-show="curb.curb_type_id == c.VERT_CURB" class="col-md-3 col-sm-4 col-xs-6 form-group">
                  <label>Top Offset</label>
                  <select v-model="curb.top_offset_id" class="form-control">
                    <option v-for="[topOffsetID, topOffset] in c.TOP_OFFSET" :value="topOffsetID" :key="topOffsetID">{{ topOffset }}</option>
                  </select>
                </div>
                <div v-show="curb.curb_type_id == c.EXHAUST_FAN_CURB || curb.curb_type_id == c.EQUIPMENT_RAILS" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Length <small>(inches)</small></label>
                    <input type="text" v-model="curb.length_ft" class="form-control">
                </div>
                <div v-show="curb.curb_type_id == c.EXHAUST_FAN_CURB" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label></label>
                    <select v-model="curb.length_inch_id" class="form-control">
                        <option v-for="[inchFractionID, inchFraction] in c.INCHES_FRACTIONS" :value="inchFractionID" :key="inchFractionID">{{ inchFraction }}</option>
                    </select>
                </div>

                <div v-show="curb.curb_type_id == c.EQUIPMENT_RAILS" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>ER Model</label>
                    <select v-model="curb.cdi_part_id_new" class="form-control">
                        <option value="886" >ER100</option>
                        <option value="887" >ER200</option>
                        <option value="888" >ER300</option>
                    </select>
                </div>

                <div v-show="curb.curb_type_id == c.MULTI_ZONE_ADAPTERS" class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Number of Zones</label>
                    <input v-model="curb.spacing" type="text" class="form-control">
                </div>
            </div>

            <div v-if="$appStore.isStaff()" class="row">
                <div v-show="showCM" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                    <label>
                        <input :true-value="1" :false-value="0" type="checkbox" v-model="curb.is_custom"> CM
                    </label>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                    <label>
                        <input v-model="curb.applied" type="checkbox" true-value="1" false-value="0"> Custom</label>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                    <label>
                        <input type="checkbox" true-value="1" false-value="0" v-model="curb.sectional"> Sectional</label>
                </div>
            </div>

            <div v-show="showNew" class="well well-sm">
                <b>Model Search Help</b>
                <ul>
                    <li>Start Typing your <i>Model number</i> and options will begin to appear below</li>
                    <li>Don't use spaces or dashes (-)</li>
                    <li>Select the model from the list</li>
                </ul>
            </div>

            <div v-show="showIso" class="spacing_btm">
                <b>Accessories on RTU</b>
                <div><small>Accessories affect spring layout</small></div>
                    <div v-for="[rtuAccessoryID, rtuAccessory] in c.RTU_ACCESSORIES" :key="rtuAccessoryID">
                        <label><input type="checkbox" v-model="curb.rtu_accessories" :value="rtuAccessoryID"> {{ rtuAccessory }}</label>
                    </div>
            </div>

            <div v-show="showConforming" class="spacing_btm">
                <div>
                    <b>Is this a Conforming/Seismic Curb?</b> <a href="/conforming" target="_blank"><small>What is this?</small></a><br>
                    <label><input type="radio" v-model="curb.conforming" value="1" > Yes</label>
                    <label><input type="radio" v-model="curb.conforming" value="0" checked> No</label>
                    <div><small>A conforming/seismic roof curb has been engineered and stamped to meet your selected code requirements.  This will add to the cost.</small></div>
                </div>
              <div v-if="editCurb.conforming" class="badge badge-primary"> Conforming {{ editCurb.conforming_type }}</div>
              <div v-if="editCurb.conforming">
                <input type="button" value="Edit Conforming Type" @click="editConformingType = true" class="btn btn-default">
              </div>
              <div v-show="showConformingType">
                <div class="form-group">
                  <label>Conforming Stamp Location</label>
                  <select class="form-control" v-show="curb.conforming" v-model="curb.conforming_location_id" @change="get_conforming_stamp_types()">
                    <option v-for="[id, type] in this.conformingStampLocations" v-bind:key="id" :value="id">
                      {{ type }}
                    </option>
                  </select>
                </div>
                <div class="spacing_btm" v-show="curb.conforming_location_id === 2">
                  <b>Is this an OSHPD (Isolation Spring Restraints) Curb?</b><br>
                  <label><input type="radio" v-model="curb.is_oshpd" value="1" > Yes</label>
                  <label><input type="radio" v-model="curb.is_oshpd" value="0" checked> No</label>
                </div>
                <div class="form-group" v-show="curb.conforming_location_id">
                  <label>Code Year</label>
                  <select class="form-control" v-model="curb.conforming_type_id">
                    <option v-for="[id, year] in conformingStampCodeYears" v-bind:key="id" :value="id">
                      {{ year }}
                    </option>
                  </select>
                </div>
              </div>
            </div>


            <div v-if="showAddOns" class="spacing_btm">
                <b>Add Ons</b><br>
                <div class="form-group">
                    <!-- 2" insulation for adapters-->
                    <label v-show="curb.curb_type_id == c.ADAPTER || curb.curb_type_id == c.STRADAPTER || curb.curb_type_id == c.VERT_CURB">
                        <input :true-value="2" :false-value="0" v-model="curb.variant_insulation_size" type="checkbox"> 2" Insulation
                    </label>
                    <div v-show="curb.curb_type_id == c.ADAPTER || curb.curb_type_id == c.STRADAPTER || curb.curb_type_id == c.VERT_CURB"><small>Adapters come standard with 1" insulation (R3.85). 2" insulation (R8) will increase the cost.</small></div>

                    <!-- 3" insulation for adapters-->
                    <label v-show="curb.curb_type_id == c.ADAPTER || curb.curb_type_id == c.STRADAPTER || curb.curb_type_id == c.EXTENSION|| curb.curb_type_id == c.VERT_CURB || curb.curb_type_id == c.STRAIGHT_CURB || curb.curb_type_id == c.PITCHED">
                        <input :true-value="3" :false-value="0" v-model="curb.variant_insulation_size" type="checkbox"> 3" Insulation
                    </label>
                    <div v-show="curb.curb_type_id == c.ADAPTER || curb.curb_type_id == c.STRADAPTER || curb.curb_type_id == c.EXTENSION || curb.curb_type_id == c.VERT_CURB"><small>Adapters come standard with 1" insulation (R3.85). 3" insulation (R12) will increase the cost.</small></div>

                    <!-- 1" insulation for straight / iso -->
                    <label v-show="curb.curb_type_id == c.STRAIGHT_CURB || curb.curb_type_id == c.ISO_CURB|| curb.curb_type_id == c.VRF_STRAIGHT_CURB">
                        <input :true-value="1" :false-value="0" v-model="curb.variant_insulation_size" type="checkbox"> 1" Insulation
                    </label>
                    <div v-show="curb.curb_type_id == c.STRAIGHT_CURB || curb.curb_type_id == c.ISO_CURB || curb.curb_type_id == c.VRF_STRAIGHT_CURB"><small>Straight curbs come standard with no insulation. 1" insulation (R3.85) will increase the cost.</small></div>
                </div>
            </div>
            <div class="btn-group">
                <input type="button" value="Save" @click="save" class="btn btn-default">
                <input v-if="quoteID" type="button" value="Save and Add More" @click="add" class="btn btn-default">
                <input type="button" value="Cancel" @click="cancel" class="btn btn-default">
                <button class="btn btn-default" @click="openModal('dimensions')" title="Look up your curb by dimensions"><span class="badge highlight-badge">NEW</span> Lookup By Dimension</button>
            </div>
        </div>
    </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
import Typeahead from '@/components/utils/typeaheads/Typeahead';
import { store } from '@/store/BusinessObject.store.js';
import ModelHelp from './ModelHelp';
import conforming from "@/cache/conforming_stamp.cache";
import DimensionLookUp from "@/components/businessObjects/quote/DimensionLookUp";
import appStore from "../../../store/App.store";
export default {
    name: "AddItem",
    components: {
        Typeahead,
        ModelHelp,
        DimensionLookUp

    },
    props: ["quoteID", "editCurb", "jobTag", "distributorID"],
    data() {
        return {
            includeOldParts: false,
            state: store.state,
            curb: {},
            editConformingType: false,
            conformingStampCodeYears: [
              [0, 'Options Not Available']
            ],
            conformingStampLocations: [
              [0, 'Please Select New Model']],
            featured: [
                {curb_type_id: curbTypeCache.ADAPTER, curb_type: 'Curb Adapter', img: 'curbadapter.png'},
                {curb_type_id: curbTypeCache.PITCHED, curb_type: 'Pitched Curb', img: 'pitchstd.png'},
                {curb_type_id: curbTypeCache.STRAIGHT_CURB, curb_type: 'Straight Curb', img: 'stcurb.png'},
                {curb_type_id: curbTypeCache.ISO_CURB, curb_type: 'Isolation Curb', img: 'isocurb.png'},
            ],
            cache: {
                curbTypeCache,
                conforming
            },
            modelHelpContext: '',
            addAnotherCurb: '',
            computedCdiType: ''
        }
    },
    created() {
        this.computedCdiType = 'pm_model'
        // this.computedCdiType = 'pm_exclude_old_product_models'
    },
    computed: {
        appStore() {
            return appStore
        },
        view: function() {
            return this.state.currentView;
        },
        imgServer: function() {
            return process.env.VUE_APP_URL;
        },
        moreProducts: function() {
            let moreProducts = [];
            let allProducts = this.cache.curbTypeCache.CURB_TYPES;
            for (let i = 0; i < allProducts.length; i++){
                if (![this.c.ADAPTER, this.c.STRAIGHT_CURB, this.c.PITCHED, this.c.ISO_CURB].includes(allProducts[i][0])){
                    if (this.$appStore.isStaff() || (!this.$appStore.isStaff() && ![this.c.CUSTOM, this.c.MISCELLANEOUS].includes(allProducts[i][0]))) { // products for staff only
                        moreProducts.push(allProducts[i]);
                    }
                }
            }
            return moreProducts;
        },
        c: function() {
            return this.cache.curbTypeCache;
        },
        showSideType: function() {
            return [this.c.STRAIGHT_CURB, this.c.ADJUSTABLE_PITCHED_CURB, this.c.EXHAUST_FAN_CURB, this.c.EQUIPMENT_RAILS, this.c.ISO_CURB, this.c.VRF_STRAIGHT_CURB].includes(this.curb.curb_type_id);
        },
        sideTypes: function() {
            let sideTypes = [];
            if (this.curb.curb_type_id == this.c.STRAIGHT_CURB) {
                sideTypes = [this.c.SIDE_TYPES[0], this.c.SIDE_TYPES[7]];
            }else if (this.curb.curb_type_id == this.c.ADJUSTABLE_PITCHED_CURB) {
                sideTypes = [this.c.SIDE_TYPES[5]];
            }else if (this.curb.curb_type_id == this.c.ISO_CURB) {
                sideTypes = [this.c.SIDE_TYPES[0], this.c.SIDE_TYPES[1], this.c.SIDE_TYPES[2]];
            }else if (this.curb.curb_type_id == this.c.EXHAUST_FAN_CURB) {
                sideTypes = [this.c.SIDE_TYPES[0], this.c.SIDE_TYPES[7], this.c.SIDE_TYPES[1], this.c.SIDE_TYPES[2], this.c.SIDE_TYPES[6], this.c.SIDE_TYPES[3], this.c.SIDE_TYPES[4]];
            }else if (this.curb.curb_type_id == this.c.EQUIPMENT_RAILS) {
                sideTypes = [this.c.SIDE_TYPES[0], this.c.SIDE_TYPES[1], this.c.SIDE_TYPES[2], this.c.SIDE_TYPES[6]];
            }else if (this.curb.curb_type_id == this.c.VRF_STRAIGHT_CURB) {
              sideTypes = [this.c.SIDE_TYPES[0]];
            }

            return sideTypes;
        },
        showPitch: function() {
            if ((this.curb.curb_type_id == this.c.EQUIPMENT_RAILS || this.curb.curb_type_id == this.c.ISO_CURB || this.curb.curb_type_id == this.c.EXHAUST_FAN_CURB) && this.curb.side_type_id !== 0) {
                return true
            }
            return [this.c.PITCHED, this.c.METAL_BUILDING_CURB, this.c.VRF_PITCHED_CURB].includes(this.curb.curb_type_id);
        },
        showHeight: function() {
            return [this.c.PITCHED, this.c.METAL_BUILDING_CURB, this.c.STRAIGHT_CURB, this.c.ADJUSTABLE_PITCHED_CURB, this.c.EXHAUST_FAN_CURB, this.c.EQUIPMENT_RAILS, this.c.ISO_CURB, this.c.EXTENSION, this.c.VRF_STRAIGHT_CURB, this.c.VRF_PITCHED_CURB].includes(this.curb.curb_type_id);
        },
        showOld: function() {
            return [this.c.ADAPTER, this.c.MULTI_ZONE_ADAPTERS, this.c.ISO_ADAPTER, this.c.STRADAPTER, this.c.VERT_CURB].includes(this.curb.curb_type_id);
        },
        showNew: function() {
            return [this.c.ADAPTER, this.c.PITCHED, this.c.METAL_BUILDING_CURB, this.c.STRAIGHT_CURB, this.c.ADJUSTABLE_PITCHED_CURB, this.c.BURGLAR_BAR, this.c.ISO_RAIL, this.c.ISO_CURB, this.c.MULTI_ZONE_ADAPTERS, this.c.ERV_COMBO, this.c.CLIPS, this.c.ISO_ADAPTER, this.c.EXTENSION, this.c.STRADAPTER, this.c.VERT_CURB].includes(this.curb.curb_type_id);
        },
        showNewVrf: function() {
          return [this.c.VRF_STRAIGHT_CURB, this.c.VRF_PITCHED_CURB].includes(this.curb.curb_type_id);
        },
        showCM: function() {
            return [this.c.CLIPS, this.c.EXHAUST_FAN_CURB, this.c.METAL_BUILDING_CURB, this.c.ADJUSTABLE_PITCHED_CURB, this.c.EQUIPMENT_RAILS, this.c.ADAPTER, this.c.ISO_CURB, this.c.ISO_RAIL, this.c.MULTI_ZONE_ADAPTERS, this.c.ISO_ADAPTER, this.c.STRAIGHT_CURB, this.c.PITCHED, this.c.EXTENSION, this.c.STRADAPTER, this.c.VERT_CURB, this.c.VRF_STRAIGHT_CURB, this.c.VRF_PITCHED_CURB].includes(this.curb.curb_type_id);
        },
        showIso: function() {
            return [this.c.ISO_CURB, this.c.ISO_RAIL, this.c.ISO_ADAPTER].includes(this.curb.curb_type_id);
        },
        showConforming: function() {
            return [this.c.ADAPTER, this.c.PITCHED, this.c.ISO_CURB, this.c.STRAIGHT_CURB, this.c.EQUIPMENT_RAILS, this.c.ISO_ADAPTER, this.c.STRADAPTER, this.c.ERV_COMBO, this.c.EXHAUST_FAN_CURB, this.c.MULTI_ZONE_ADAPTERS, this.c.EXTENSION, this.c.CUSTOM, this.c.VERT_CURB].includes(this.curb.curb_type_id);
        },
        showAddOns: function() {
            return [this.c.ADAPTER, this.c.STRADAPTER, this.c.STRAIGHT_CURB, this.c.ISO_CURB, this.c.EXTENSION, this.c.VERT_CURB, this.c.PITCHED].includes(this.curb.curb_type_id);
        },
        selectedExtensionId: function() {
            return this.curb.extension_id;
        },
        showConformingType: function() {
            if (!this.editCurb.curb_id) {
                return (this.curb.conforming);
            } else if (this.editCurb.conforming) {
                return this.editConformingType;
            } else {
                return this.curb.conforming
            }
        }
    },
    methods: {
        selectCurbType: function(curbTypeID, curbType) {
            this.setCurbDefaults(curbTypeID, curbType);
        },
        setCurbDefaults: function(curbTypeID, curbType) {
            // clear typeaheads
            this.$refs.oldModel.$data.object = '';
            this.$refs.newModel.$data.object = '';
            this.$refs.newModelVrf.$data.object = '';
            this.$refs.customPart.$data.object = '';
            this.$refs.miscPart.$data.object = '';
            this.$refs.ervModel.$data.object = '';

            this.curb = {
                curb_depth_id: 0,
                curb_type_id: curbTypeID,
                curb_type: curbType,
                qty: 1,
                price_override: '',
                tag: '',
                side_type_id: 0,
                extension_id: 0,
                pitch_id: 0,
                base_height: 0,
                eave_peak_selection: 0,
                spacing: 0,
                diameter: 0,
                frame: 0,
                cdi_part_id_old: 0,
                old_model_id: 0,
                old_model: '',
                cdi_part_id_new: 0,
                new_model_id: 0,
                new_model: '',
                width_ft: 0,
                width_inch_id: 0,
                length_ft: 0,
                length_inch_id: 0,
                is_custom: 0,
                applied: 0,
                sectional: 0,
                rtu_accessories: [],
                conforming: 0,
                variant_insulation_size: 0,
                curb_estimate_id: 0,
                conforming_location_id: 0,
                conforming_type: 0,
                conforming_type_id: 0,
                is_oshpd: 0,
                top_offset_id: 0,
            }
            switch (curbTypeID) {
                case this.c.PITCHED:
                case this.c.STRAIGHT_CURB:
                case this.c.METAL_BUILDING_CURB:
                    this.curb.base_height = 14;
                    break;
                case this.c.ISO_CURB:
                    this.curb.base_height = 11;
                    break;
                case this.c.EXHAUST_FAN_CURB:
                    this.curb.base_height = 14;
                    this.curb.width_ft = 6;
                    this.curb.length_ft = 6;
                    break;
                case this.c.BURGLAR_BAR:
                    this.curb.spacing = 6;
                    break;
                case this.c.ADJUSTABLE_PITCHED_CURB:
                    this.curb.base_height = 14;
                    this.curb.side_type_id = 5;
                    this.curb.pitch_id = 9;
                    break;
                case this.c.EQUIPMENT_RAILS:
                    this.curb.base_height = 14;
                    this.curb.length_ft = 6;
                    this.curb.cdi_part_id_new = 886;
                    break;
                case this.c.ISO_RAIL:
                    this.curb.old_model_id = 5374;
                    this.cdi_part_id_old = 1119;
                    break;
                case this.c.CLIPS:
                    this.curb.cdi_part_id_old = 872;
                    break;
                case this.c.EXTENSION:
                    this.curb.extension_id = 1;
                    this.curb.base_height = 14;
                    break;
            }

        },
        pmSelect: function(field, selected) {
            this.curb[`cdi_part_id_${field}`] = selected.cdi_part_id;
            this.curb[`${field}_model_id`] = selected.unit_model_id;
            this.curb[`${field}_model`] = selected.label;
            this.get_conforming_stamp_locations();
        },
        save: function() {
            this.editConformingType = false;
            let params = {};
            let quoteCurbID = 0;
            for (const field in this.curb) {
                if (field != 'side_type_id') {
                    params[`params[${field}]`] = this.curb[field];
                }
            }
            if(this.curb.conforming === 0){
              params['params[is_oshpd]'] = 0;
            }

            if (this.curb.side_type_id){
                params['params[side_type_id]'] = this.curb.side_type_id;
            }

            if (this.curb.variant_insulation_size != 0) {
                params['params[variant_attributes][insulation_size]'] = this.curb.variant_insulation_size;
            }
            params['params[quote_id]'] = this.quoteID;
            if (this.editCurb.quote_curb_id) {
                quoteCurbID = this.editCurb.quote_curb_id;
            }

            if (!this.quoteID) { // to create a new quote
                params['params[job_tag]'] = this.jobTag;
                params['params[distributor_id]'] = this.distributorID;
            }
            if(this.curb.side_type_id === 0 && this.curb.curb_type_id != 2){
                params['params[pitch_id]'] = 0;
            }

            let errors = this.checkForErrors();
            if (errors.length > 0) {
                this.$appStore.errorBox(errors);

                return;
            }
            store.apiGeneric(`quote_curb/save_quote_curb_data/${quoteCurbID}`, params)
                .then(results => {
                    if (!this.quoteID && results.quote_id) {
                        this.$router.push({ path: `/quote/${results.quote_id}` });
                    }
                    if (this.addAnotherCurb) {
                        this.$emit('changeView', {view: 'addItem', reload: true});
                    } else {
                        this.$emit('changeView', {view: 'details', reload: true});
                    }

                    this.curb.curb_type_id = 0;
                    this.addAnotherCurb = '';
                });
        },
        add: function () {
            this.addAnotherCurb = true;
            this.save();
        },
        cancel: function() {
            this.curb.curb_type_id = 0;
            this.$emit('changeView', {view: 'details'});
        },
        showHelp: function(context) {
            // context can be "new", "old", or "erv"
            this.modelHelpContext = context;
        },
        load: function() {

            this.setCurbDefaults(this.editCurb.curb_type_id, this.editCurb.curb_type);
            for (const field in this.curb) {
                this.curb[field] = this.editCurb[field];
                if (typeof this.curb[field] == 'boolean'){
                    this.curb[field] = this.curb[field] ? 1 : 0;
                }
            }
            this.get_conforming_stamp_locations();
            this.curb.price_override = this.curb.price_override > 0 ? this.curb.price_override : '';

            if (this.editCurb.variant) {
                if (this.editCurb.variant['one_inch_insulation']) {
                    this.curb.variant_insulation_size = 1
                } else if (this.editCurb.variant['two_inch_insulation']) {
                    this.curb.variant_insulation_size = 2;
                } else if (this.editCurb.variant['three_inch_insulation']) {
                    this.curb.variant_insulation_size = 3
                } else {
                    this.curb.variant_insulation_size = 0;
                }
            }

            // set typeaheads
            this.$refs.oldModel.$data.object = this.editCurb.old_model ? this.editCurb.old_model : this.editCurb.old_part_num;
            this.$refs.newModel.$data.object = this.editCurb.new_model ? this.editCurb.new_model : this.editCurb.new_part_num;
            this.$refs.customPart.$data.object = this.editCurb.old_model ? this.editCurb.old_model : this.editCurb.old_part_num;
            this.$refs.miscPart.$data.object = this.editCurb.old_model ? this.editCurb.old_model : this.editCurb.old_part_num;
            this.$refs.ervModel.$data.object = this.editCurb.old_model ? this.editCurb.old_model : this.editCurb.old_part_num;

        },
        checkForErrors: function () {

            let curbTypeID = this.curb.curb_type_id;

            if (this.curb.qty < 1 || isNaN(parseInt(this.curb.qty))) {
                return 'Invalid Qty';
            }

         if (this.curb.qty > 1000 && !this.$appStore.hasRole(this.$cdiVars.CDI_ROLE_SUPER)) {
            return 'Qty limit is set at 1000, if you need to increase this call your sales person.';
          }

            if ([this.c.PITCHED, this.c.METAL_BUILDING_CURB, this.c.VRF_PITCHED_CURB].includes(curbTypeID) && parseInt(this.curb.pitch_id) === 0) {

                return 'Invalid Pitch';
            }

            let adapterTypes = [
                this.c.ADAPTER,
                this.c.MULTI_ZONE_ADAPTERS,
                this.c.ISO_ADAPTER,
                this.c.STRADAPTER,
                this.c.ERV_COMBO,
                this.c.VERT_CURB
            ];
            if (adapterTypes.includes(curbTypeID) && (!parseInt(this.curb.old_model_id) && !parseInt(this.curb.cdi_part_id_old))) {
                return 'Need to select Old Model from the drop-down list';
            }

            let newModelPartTypes = [
                this.c.ADAPTER,
                this.c.PITCHED,
                this.c.METAL_BUILDING_CURB,
                this.c.STRAIGHT_CURB,
                this.c.BURGLAR_BAR,
                this.c.ADJUSTABLE_PITCHED_CURB,
                this.c.ISO_RAIL,
                this.c.RTU_DOUBLE_WALL,
                this.c.ERV_COMBO,
                this.c.CLIPS,
                this.c.MULTI_ZONE_ADAPTERS,
                this.c.ISO_CURB,
                this.c.STRADAPTER,
                this.c.VERT_CURB,
                this.c.VRF_STRAIGHT_CURB,
                this.c.VRF_PITCHED_CURB
            ];
            if (newModelPartTypes.includes(curbTypeID) && (!parseInt(this.curb.new_model_id) && !parseInt(this.curb.cdi_part_id_new))) {
                return 'Need to select a New Model from the drop-down list';
            }

            if (this.curb.variant_insulation_size > 0 && this.curb.is_custom) {
                return 'Alternate insulation should be set in an estimator for CM items.';
            }

            // job tag required for new quotes
            if (!this.quoteID && !this.jobTag){
                return 'Job Tag is required.';
            }
            if(this.curb.conforming == 1 && !this.curb.conforming_type_id){
              return 'Conforming Stamp Location and Year is required.';
            }
            if(curbTypeID === this.c.VERT_CURB && !this.curb.curb_depth_id) {
              return 'Curb Depth is required.'
            }
            if(curbTypeID === this.c.VERT_CURB && !this.curb.top_offset_id) {
              return 'Top Offset is required.'
            }
            if ([this.c.PITCHED, this.c.METAL_BUILDING_CURB, this.c.VRF_PITCHED_CURB].includes(curbTypeID) && !this.curb.eave_peak_selection) {
              return 'Please select an Eave or Peak option.';
            }
            return '';
        },
        selectModel: function(model) {
            if (this.modelHelpContext == 'old') {
                this.curb.old_model_id = model.unit_model_id;
                this.curb.old_model = model.unit_model_num;
                this.$refs.oldModel.$data.object = model.unit_model_num;
            } else if (this.modelHelpContext == 'erv') {
                    this.curb.old_model_id = model.unit_model_id;
                    this.curb.old_model = model.unit_model_num;
                    this.$refs.ervModel.$data.object = model.unit_model_num;
            } else {
                this.curb.new_model_id = model.unit_model_id;
                this.curb.new_model = model.unit_model_num;
                this.$refs.newModel.$data.object = model.unit_model_num;
            }
            this.modelHelpContext = '';
        },
        selectPart: function(part) {
            if (this.modelHelpContext == 'old') {
                this.curb.cdi_part_id_old = part.cdi_part_id;
                this.curb.old_model_id = '';
                this.curb.old_model = part.cdi_part_num;
                this.$refs.oldModel.$data.object = part.cdi_part_num;
            } else if (this.modelHelpContext == 'erv') {
                this.curb.cdi_part_id_old = part.cdi_part_id;
                this.curb.old_model_id = '';
                this.curb.old_model = part.cdi_part_num;
                this.$refs.ervModel.$data.object = part.cdi_part_num;
            } else {
                this.curb.cdi_part_id_new = part.cdi_part_id;
                this.curb.new_model_id = '';
                this.curb.new_model = part.cdi_part_num;
                this.$refs.newModel.$data.object = part.cdi_part_num;
            }
            this.modelHelpContext = '';
        },
        get_conforming_stamp_types: function() {
          let options = {
            'params[conforming_location_id]': this.curb.conforming_location_id
          }
          store.customApi('menu_builder/conforming_stamp_code_years', options).then(
              res => {
                this.conformingStampCodeYears = res;
                this.curb.conforming_type_id = res[0][0]});
        },
        get_conforming_stamp_locations: function (type = '') {
          let options = {
            'params[curb_type_id]': this.curb.curb_type_id,
            'params[new_model_num]': this.curb.new_model
          }
          if(type === 'custom'){
             options = {
              'params[curb_type_id]': this.curb.curb_type_id,
              'params[new_model_num]': this.curb.cdi_part_id_old
            }
          }
          store.customApi('menu_builder/conforming_stamp_locations', options).then(
              res => {
                this.conformingStampLocations = res;
              }
          )
      },
        openModal: function(type) {
            if(type === 'dimensions'){
                this.$refs.dimensions.open();
            }
        },
        includeOldPartsFromOptions: function (){
            this.computedCdiType = 'pm_model'

            // if(this.includeOldParts === true){
            //     this.computedCdiType = 'pm_model'
            // } else if(this.includeOldParts === false){
            //     this.computedCdiType = 'pm_exclude_old_product_models'
            // }
        }
    },
    watch: {
        view: function() {
            if (this.view == 'editItem') {
                this.load();
            }
        },
        selectedExtensionId: function() {
            if (this.curb.curb_type_id == this.c.EXTENSION) {
                if (this.curb.extension_id == 3) {
                    this.curb.variant_insulation_size = 3;
                }
            }
        }
    },
}
</script>

<style scoped>
.highlight-text {
    color: red;
}
.highlight-badge {
    background-color: red;
    color: white;
}
</style>